import React, { useEffect, useState } from "react";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import { cleanName, isRangeSubset, rangeStringToArray } from "../AppManager";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { nanoid } from "nanoid";

export default function Search() {
  const params = useSearchParams()[0];
  const next = params.get("next");
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    department: "",
    level: "",
    hostel: "",
    block: "",
    room: "",
  });
  const [loading, setLoading] = useState(false);
  const [maxLevel, setMaxLevel] = useState(600);
  const { departments, hostels } = useOutletContext();
  const navigate = useNavigate();

  let hosts = hostels
    .filter((hos) => formData.gender === hos.gender)
    .map(({ hostel }) => hostel);
  hosts = Array.from(new Set(hosts));

  let blocks = [];
  hostels
    .filter(
      (hos) => formData.hostel === hos.hostel && formData.gender === hos.gender
    )
    .map(({ block, brange }) => {
      const range = rangeStringToArray(brange);
      range.map((rg) => {
        blocks.push(`${block} ${rg}`);
        return rg;
      });
      return block;
    });
  blocks = Array.from(new Set(blocks));

  let rooms = [];
  hostels
    .filter(
      (hos) =>
        formData.hostel === hos.hostel &&
        formData.block.split(" ").slice(0, -1).join(" ") === hos.block &&
        isRangeSubset(formData.block.split(" ").pop(), hos.brange) &&
        formData.gender === hos.gender
    )
    .map(({ room, rrange }) => {
      const range = rangeStringToArray(rrange);
      range.map((rg) => {
        rooms.push(`${room} ${rg}`);
        return rg;
      });
      return room;
    });
  rooms = Array.from(new Set(rooms));

  useEffect(() => {
    !next && navigate("/");
  }, [navigate, next]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({
      ...oldData,
      [name]: name === "name" ? cleanName(value) : value,
    }));
    if (name === "department") {
      if (value) {
        const selected = departments.filter((dept) => dept.programme === value);
        setMaxLevel(selected[0].maxlevel);
      } else {
        setMaxLevel(600);
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    navigate(`result?next=${next}`, { state: { ...formData } });
  }

  const departmentElement = departments.map((dept) => (
    <option value={dept.programme} key={nanoid()}>
      {dept.programme}
    </option>
  ));

  const levelElements = [];
  if (maxLevel !== 0) {
    for (let i = 100; i <= maxLevel; i += 100) {
      levelElements.push(
        <option value={i} key={nanoid()}>
          {i}
        </option>
      );
    }
  }

  document.title = "Student Search";

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Student Search</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.name}
              minLength={3}
              maxLength={30}
              required
              id="name"
              name="name"
              placeholder="Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.gender}
              name="gender"
              id="gender"
            >
              <option disabled value="">
                Choose one...
              </option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="department">Department</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.department}
              name="department"
              id="department"
            >
              <option value="">Choose one...</option>
              {departmentElement}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="level">Level</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.level}
              id="level"
              name="level"
            >
              <option value="">Choose one...</option>
              {levelElements}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="hostel">Hostel</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.hostel}
              name="hostel"
              id="hostel"
            >
              <option disabled value="">
                Choose one...
              </option>
              {!formData.gender && (
                <option disabled>Choose Gender First</option>
              )}
              {hosts.map((hostel) => (
                <option key={nanoid()} value={hostel}>
                  {hostel}
                </option>
              ))}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="block">Block</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.block}
              name="block"
              id="block"
            >
              <option disabled value="">
                Choose one...
              </option>
              {!formData.hostel && (
                <option disabled>Choose Hostel First</option>
              )}
              {blocks.map((block) => (
                <option key={nanoid()} value={block}>
                  {block}
                </option>
              ))}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="room">Room</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.room}
              name="room"
              id="room"
            >
              <option disabled value="">
                Choose one...
              </option>
              {!formData.block && <option disabled>Choose Block First</option>}
              {rooms.map((room) => (
                <option key={nanoid()} value={room}>
                  {room}
                </option>
              ))}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Search"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
